
import Vue from 'vue'
export default Vue.extend({
    
    computed: {
        username() {
            if(this.$store.getters['auth/isLogged'] == false) {
                return ''
            }
            
            return this.$store.getters['auth/user'].displayName
        },

        enterprise() {
            if(this.$store.getters['auth/isLogged'] == false) {
                return ''
            }

            const user = this.$store.getters['users/user'](this.$store.getters['auth/user'].uid)
            if(!user) {
                return ''
            }

            return user.enterprise
        }
    }

})
